import React from 'react'
import {list, listItem} from './ListWithWhiteBg.module.scss'

interface Props extends React.HTMLProps<HTMLUListElement> {
  items: string[]
}

const ListWithWhiteBg = (props: Props) => {
  return (
    <ul {...props} className={`${props.className} ${list}`}>
      {props.items.map((item) => (
        <li key={item} className={listItem}>
          {item}
        </li>
      ))}
    </ul>
  )
}

export default ListWithWhiteBg
