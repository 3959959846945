import React from 'react'

import {icon, questionStyle} from './QuestionAndAnswer.module.scss'

import QuestionMark from './question-mark.inline.svg'

interface Props {
  question: string
  answer: string
}

const QuestionAndAnswer = ({question, answer}: Props) => {
  return (
    <div className="d-flex flex-column flex-lg-row align-items-center gap-4 gap-lg-5 p-4 p-sm-5 bg-primary-teen">
      <div className={icon}>
        <QuestionMark />
      </div>
      <div className="d-flex flex-column gap-4 gap-lg-2 text-center text-lg-start">
        <span className={questionStyle}>{question}</span>
        <span className="fs-5">{answer}</span>
      </div>
    </div>
  )
}

export default QuestionAndAnswer
