import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Col, Row} from 'react-bootstrap'
import ListWithWhiteBg from '../../../components/ListWithWhiteBg'
import Section from '../../../components/Section'
import TextNumber from '../../../components/TextNumber'
import {
  section,
  list,
  imgJay,
  imgDottedMobile,
  imgStarMobile,
  imgStar,
  imgBottom,
} from './WhatIsHpv.module.scss'

import DottedLineMobile from './img/dotted-line-mobile.inline.svg'
import DottedStarMobile from './img/dotted-line-star-mobile.inline.svg'
import DottedStar from './img/dotted-line-star.inline.svg'
import DottedBottom from './img/dotted-line-bottom.inline.svg'
import Video from '../../../components/Video'
import {VIDEO_URL} from '../../../constants'

const WhatIsHpv = () => {
  return (
    <Section
      id="what-is-hpv"
      className={section}
      shadowOverlay
      overlayImage={
        <>
          {/* Safari doesn't resize svgs in grid system for some reason hence they are wrapped in divs */}
          <div className={imgDottedMobile}>
            <DottedLineMobile />
          </div>
          <div className={imgStarMobile}>
            <DottedStarMobile />
          </div>
          <div className={imgStar}>
            <DottedStar />
          </div>
          <div className={imgBottom}>
            <DottedBottom />
          </div>

          <StaticImage
            src="./img/jay-looking-up.png"
            alt="Jay looking up."
            className={imgJay}
          />
        </>
      }
    >
      <Row>
        <Col sm={8} lg={6} xl={5} className="py-5">
          <h2>What is HPV?</h2>
          <p>
            Human papillomavirus, or HPV, will infect most people at some point
            in their lives, making HPV the most common sexually transmitted
            infection, or STI, among males and females of all ages.
          </p>
        </Col>
      </Row>
      <Row className="pb-5">
        <Col>
          <Video videoUrl={VIDEO_URL.TEENAGERS} title="Teen video." />
        </Col>
      </Row>
      <Row className="pb-5">
        <Col lg={6} className="mb-3 mb-lg-0">
          <p className="h-100 p-3 mb-0 rounded-3 bg-secondary-teen">
            HPV is a common virus that can affect both boys and girls. It is
            passed via skin-to-skin contact and is often found on the fingers,
            hands, mouth and genitals.
          </p>
        </Col>
        <Col lg={6}>
          <p className="h-100 p-3 mb-0 rounded-3 bg-tertiary-teen">
            HPV is often symptomless, and the body will usually fight it off
            itself. However, this is not always the case.
          </p>
        </Col>
      </Row>
      <Row className="pb-5 justify-content-end">
        <Col lg={6}>
          <p>
            While some HPV types are harmless, other types can cause damage to
            cells that may cause abnormal growth, which can lead to:
          </p>
          <ListWithWhiteBg
            className={list}
            items={['Genital warts', 'Cervical cancer', 'Anal cancer']}
          />
        </Col>
      </Row>
      <Row className="pb-3 mb-sm-5">
        <Col sm={6} lg={5} xl={6} className="d-none d-sm-block" />
        <Col sm={6} lg={4} xl={3} className="pb-5">
          <TextNumber
            topText="There are more than"
            number={100}
            bottomText="different types of HPV"
          />
        </Col>
        <Col sm={6} className="d-none d-sm-block d-lg-none" />
        <Col sm={6} lg={3} xxl={2}>
          <TextNumber
            topText="And around"
            number={40}
            bottomText="of these affect the genital area"
            numberColor="tertiary-teen"
          />
        </Col>
      </Row>
    </Section>
  )
}

export default WhatIsHpv
