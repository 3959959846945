import React from 'react'
import {Footer, HeaderTeenage} from '../../components/Layout'
import SEO from '../../components/SEO'
import ArmsAgainst from '../../sections/teenagers/ArmsAgainst'
import BeWarrior from '../../sections/teenagers/BeWarrior'
import JoinTheFight from '../../sections/teenagers/JoinTheFight'
import SideEffects from '../../sections/teenagers/SideEffects'
import WhatElseKnow from '../../sections/teenagers/WhatElseKnow'
import WhatIsHpv from '../../sections/teenagers/WhatIsHpv'
import WhyNeedVaccine from '../../sections/teenagers/WhyNeedVaccine'

const pageCertificate = {
  jobCode: 'GB-HPV-00210',
  dateOfPrep: 'May 2023',
}

const TeenagersPage = () => {
  return (
    <>
      <SEO />
      <HeaderTeenage />
      <main>
        <JoinTheFight />
        <WhatIsHpv />
        <ArmsAgainst />
        <WhyNeedVaccine />
        <WhatElseKnow />
        <SideEffects />
        <BeWarrior />
      </main>
      <Footer {...pageCertificate} />
    </>
  )
}

export default TeenagersPage
