import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import Section from '../../../components/Section'

import {section, list} from './ArmsAgainst.module.scss'

import ListWithWhiteBg from '../../../components/ListWithWhiteBg'

const ArmsAgainst: React.FC = () => {
  return (
    <Section id="arms-against" className={section} shadowOverlay>
      <Row>
        <Col lg={6} className="py-5">
          <h2 className="mb-4">Boys and girls, take up arms against HPV</h2>
          <p className="mb-4">
            Vaccination against HPV can help prevent infections, meaning you can
            defend yourself against damage to cells that can turn into certain
            cancers or genital warts.
          </p>
          <p className="mb4">Look out for more information from your school.</p>
          <p className="mb-4">
            You do not have to be vaccinated against HPV if you do not want to,
            but it is a good idea to discuss any questions or concerns with:
          </p>
          <ListWithWhiteBg
            className={list}
            items={[
              'Your parents/carers',
              'The school immunisation team',
              'Your GP or practice nurse',
            ]}
          />
        </Col>
        <Col
          lg={6}
          className="d-flex align-items-end justify-content-center px-5 px-sm-0"
        >
          <StaticImage
            alt="Boy and Girl supporting HPV Wise vaccination"
            src="./Thomas.png"
            width={460}
          />
        </Col>
      </Row>
    </Section>
  )
}

export default ArmsAgainst
