import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'

import Section from '../../../components/Section'
import TextCheckmark from '../../../components/TextCheckmark'

import DottedIcons from './img/dotted-icons.inline.svg'

import {section, bgImage} from './WhyNeedVaccine.module.scss'

const WyNeedVaccine = () => {
  return (
    <Section
      id="why-need-vaccine"
      className={section}
      shadowOverlay
      overlayImage={<DottedIcons className={bgImage} />}
    >
      <Row>
        <Col xl={6} className="d-flex flex-column py-5 pe-xxl-5 order-xl-2">
          <h2 className="my-5 mb-xxl-4 pt-lg-4 pt-xl-0">
            Why do I need to be vaccinated now?
          </h2>
          <div className="d-flex flex-column gap-5 mb-xl-5 mb-xxl-4 mt-xxl-2">
            <TextCheckmark>
              As a teenager, your immune system is geared up and ready to fight.
              Your risk of having had HPV is also much lower than when you are
              older.
            </TextCheckmark>
            <TextCheckmark>
              Getting vaccinated now means you could reduce your risk of genital
              warts or certain cancers caused by HPV.
            </TextCheckmark>
            <TextCheckmark>
              Vaccination against HPV is most effective when given before any
              exposure to HPV viruses, so it is first offered to 12-13 year olds
              (school year 8/S1).
            </TextCheckmark>
            <TextCheckmark>
              If you are aged 12–13, you can fight back against HPV as part of
              the NHS National Immunisation Programme (NIP).
            </TextCheckmark>
            <TextCheckmark>
              HPV vaccination is given as a free injection in the upper arm.
              This injection will help to protect you against HPV infection and
              reduce your risk of genital warts and certain cancers in the
              future.
            </TextCheckmark>
          </div>
        </Col>
        <Col
          xl={6}
          className="d-flex align-items-end justify-content-center px-5 px-sm-0 order-xl-1"
        >
          <StaticImage
            src="./img/amber-looking-up.png"
            alt="Girl looking up"
            width={600}
          />
        </Col>
      </Row>
    </Section>
  )
}

export default WyNeedVaccine
