import React from 'react'
import {Col, Row} from 'react-bootstrap'

import Section from '../../../components/Section'
import ListWithWhiteBg from '../../../components/ListWithWhiteBg'

import DottedImage from './img/dotted-bg.inline.svg'
import ExlamationCircle from './img/exclamation-mark-cricle.inline.svg'
import VaccineCircle from './img/vaccine-circle.inline.svg'
import DottedLineLeft from './img/dotted-line-left.inline.svg'
import DottedLineRight from './img/dotted-line-right.inline.svg'

import {
  section,
  bgImage,
  imageExclamation,
  dottedLineLeftExclamation,
  dottedLineLeft,
  dottedLineRight,
} from './SideEffects.module.scss'

import QuestionAndAnswer from '../../../components/QuestionAndAnswer'

const SideEffects = () => {
  return (
    <Section
      id="side-effects"
      className={section}
      shadowOverlay
      overlayImage={<DottedImage className={bgImage} />}
    >
      <Row className="justify-content-center">
        <Col lg={7} xl={8} className="text-center">
          <h2 className="my-5">What about side effects?</h2>
          <p className="fs-5">
            As with any vaccines and medicines, HPV vaccinations may cause side
            effects which affect people differently. Some people don’t have any
            side effects after HPV vaccination.
          </p>
          <div className="position-relative">
            <DottedLineLeft className={dottedLineLeftExclamation} />
            <ExlamationCircle className={imageExclamation} />
          </div>
        </Col>
      </Row>

      <Row className="justify-content-evenly">
        <Col lg={6} xl={5} className="pt-5 pb-4">
          <h6 className="mb-4">The most common side effects are:</h6>
          <div className="d-flex flex-column gap-5 mb-lg-5">
            <ListWithWhiteBg
              items={[
                'Redness, swelling or pain at the site of injection',
                'Headaches',
              ]}
            />
          </div>
          <div className="position-relative text-center mt-5">
            <DottedLineLeft className={dottedLineLeft} />
            <VaccineCircle className="mx-auto d-lg-none text-center" />
            <DottedLineRight className={dottedLineRight} />
          </div>
        </Col>
        <Col lg={6} xl={5} className="py-5">
          <h6 className="mb-4">Some people might get:</h6>
          <div className="d-flex flex-column gap-5 mb-lg-5">
            <ListWithWhiteBg
              items={[
                'Bruising or itching at the site of the injection',
                'A high temperature or feeling hot and shivery',
                'Feeling sick (nausea)',
                'Pain in the arms, hands, fingers, legs, feet or toes',
              ]}
            />
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center pb-5">
        <Col xxl={10}>
          <QuestionAndAnswer
            question="Want to find out more about how you can fight back against HPV?"
            answer="Ask your school immunisation team or doctor if you have any questions or concerns"
          />
        </Col>
      </Row>
    </Section>
  )
}

export default SideEffects
