import React from 'react'

import {text, styledNumber} from './TextNumber.module.scss'

interface Props {
  topText: string
  number: number
  bottomText: string | React.ReactNode
  numberColor?: 'primary-teen' | 'secondary-teen' | 'tertiary-teen'
}

const TextNumber = ({topText, number, bottomText, numberColor}: Props) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <span className={text}>{topText}</span>
      <span className={`${styledNumber} text-${numberColor}`}>{number}</span>
      <span className={text}>{bottomText}</span>
    </div>
  )
}

export default TextNumber
