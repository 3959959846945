import React from 'react'

import CheckmarkImage from './checkmark.inline.svg'

import {icon} from './TextCheckmark.module.scss'

interface Props {
  children: React.ReactNode
}

const TextCheckmark = ({children}: Props) => {
  return (
    <div className="d-flex align-items-start gap-3">
      <div className={icon}>
        <CheckmarkImage />
      </div>
      <div>{children}</div>
    </div>
  )
}

export default TextCheckmark
