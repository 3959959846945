import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import Section from '../../../components/Section'

import {section, textSection} from './Jointhefight.module.scss'

const JoinTheFight: React.FC = () => {
  return (
    <>
      <Section id="jointhefight" className={section} shadowOverlay>
        <Row>
          <Col lg={5} className="pt-5 position-relative">
            <div className="ps-3 ps-lg-0 py-3 pb-lg-5">
              <h1>
                Join the fight against
                <br />
                certain HPV cancers
              </h1>
              <p className="fs-4">Be a warrior, not a worrier</p>
              <p>
                Website developed, funded and provided by MSD. It is intended to
                provide general educational information and does not take the
                place of professional medical advice.
              </p>
            </div>
          </Col>
          <Col lg={7} md={10} className="mx-auto mt-auto position-relative">
            <div>
              <StaticImage
                alt="Boy and Girl supporting HPV Wise vaccination"
                src="./img/kids.png"
              />
            </div>
          </Col>
        </Row>
      </Section>
      <Section id="join-the-fight-text" className={textSection}>
        <p className="py-5 fs-5 text-center text-primary-teen">
          The power to help prevent HPV is in your hands.
        </p>
      </Section>
    </>
  )
}

export default JoinTheFight
