import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Section from '../../../components/Section'

import ImgVaccine from './img/vaccine.inline.svg'
import ImgScale from './img/scale.inline.svg'
import ImgEarth from './img/earth.inline.svg'

import {
  section,
  wrapper,
  cardText,
  cardTitle,
  cardBalance,
  cardEarth,
} from './WhatElseKnow.module.scss'

const WhatElseKnow = () => {
  return (
    <Section id="what-else-know" className={section}>
      <Row>
        <Col className="text-center py-5">
          <h2>What else do I need to know?</h2>
        </Col>
      </Row>
      <Row className="justify-content-center pb-lg-5">
        <Col md={7} xl={5} className={wrapper}>
          <div className={cardBalance}>
            {/* Safari doesn't resize svgs in grid system for some reason hence they are wrapped in divs */}
            <div>
              <ImgScale />
            </div>

            <div className={cardText}>
              <span className={cardTitle}>Vaccines</span>
              <span>
                can only be used in people once they have been proven to be
                effective. Importantly, it must be confirmed that the benefits
                of having the vaccines outweigh any risks.
              </span>
            </div>
          </div>
        </Col>
        <Col md={7} xl={5} className={wrapper}>
          <div className={cardEarth}>
            <div>
              <ImgEarth />
            </div>
            <div>
              <ImgVaccine />
            </div>
            <div className={cardText}>
              <span className="lh-1">More than</span>
              <span className={cardTitle}>
                80 million
                <br />
                people
              </span>
              <span>
                have received <br /> vaccination against HPV worldwide between
                2006 and 2022.
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  )
}

export default WhatElseKnow
