import React from 'react'
import {Col, Row, Button} from 'react-bootstrap'
import {Link} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'
import Section from '../../../components/Section'
import Arrow from './img/arrow.inline.svg'
import {section} from './BeWarrior.module.scss'

const BeWarrior = () => {
  return (
    <Section id="be-warror" shadowOverlay className={section}>
      <Row className="justify-content-between overflow-hidden">
        <Col lg={6} xl={5} xxl={4} className="py-5 position-relative">
          <h3 className="mt-3 mt-lg-3">
            The power to help prevent HPV is in your hands.
          </h3>
          <h2>Be a warrior, not a worrier</h2>
          <Link to="/" className="d-block mt-4 mb-lg-5 text-decoration-none">
            <Button
              variant="primary-teen"
              size="lg"
              className="d-flex gap-5 align-items-center border-0 px-4 py-3 text-nowrap"
            >
              Information for parents
              <Arrow />
            </Button>
          </Link>
        </Col>
        <Col
          lg={6}
          xl={7}
          xxl={8}
          className="d-flex align-items-end justify-content-center"
        >
          <StaticImage
            className="mt-5"
            src="./img/jay-and-amber.png"
            alt="Jay and Amber with raised hands."
            width={700}
          />
        </Col>
      </Row>
    </Section>
  )
}

export default BeWarrior
